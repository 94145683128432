import React from 'react'
import { graphql } from 'gatsby'
import { swapImgUrls } from '../utils/Parser'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { Col, Container, Row } from 'reactstrap'
import SEO from '../components/seo'
import defaultBg from 'assets/home-bg.jpg'

class ContactPage extends React.Component {
  render() {
    const { data } = this.props
    const pageContent = data.wordpressPage
    const introBg = pageContent.featured_media
      ? pageContent.featured_media.localFile.childImageSharp.original.src
      : defaultBg

    const html = pageContent.content
    const imageNodes = data.allWordpressWpMedia.edges
    const imageObjects = imageNodes.map(({ node }) => ({
      src: node.localFile.childImageSharp.original.src,
      fileName: node.localFile.childImageSharp.fixed.originalName,
    }))
    const parsedHtml = swapImgUrls(html, imageObjects)

    return (
      <Layout>
        <SEO
          title={`${
            pageContent.yoast.title
              ? pageContent.yoast.title
              : pageContent.title
          }`}
          description={pageContent.yoast.metadesc}
        />
        <div className="post-wrapper">
          <div className="post-content">{parsedHtml}</div>
        </div>
      </Layout>
    )
  }
}

export default ContactPage

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "contact" }) {
      ...Page
    }
    allWordpressWpMedia {
      ...Media
    }
  }
`
